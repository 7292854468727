import { useGetMedia } from "../../hooks";
import MediaSquare from "./MediaSquare";

type MediaSquareViewProps = {
  mediaId: string;
};
const MediaSquareView = (props: MediaSquareViewProps) => {
  const { data: media, error: mediaError } = useGetMedia(props.mediaId);
  return (
    <>
      {mediaError !== undefined ? "画像の読み込みに失敗しました" : null}
      {media && (
        <MediaSquare image_src={media.image_src} mediaId={props.mediaId} />
      )}
    </>
  );
};

export default MediaSquareView;
