/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExampleResponse } from '../models/ExampleResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ExampleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Hello
     * @returns ExampleResponse Successful Response
     * @throws ApiError
     */
    public getHello(): CancelablePromise<ExampleResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/example/hello',
        });
    }

    /**
     * Get User Detail
     * ログイン中のユーザの情報を返します
     * @returns any Successful Response
     * @throws ApiError
     */
    public getUserDetail(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/example/user_detail',
        });
    }

    /**
     * Get Restricted Data
     * admin group 所属ユーザのみ取得可能なデータを返します
     * @returns any Successful Response
     * @throws ApiError
     */
    public getRestrictedData(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/example/restricted_data',
        });
    }

}
