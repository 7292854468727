import { Auth } from "aws-amplify";
import {
  MediaStoreApiClient,
  OpenAPIConfig,
} from "./generatedApi/mediaStoreApi";

const isLocal = process.env.REACT_APP_API_LOCAL === "true";

export const getMediaStoreApi = async () => {
  var token: string | undefined = undefined;
  try {
    const cur = await Auth.currentSession();
    token = cur.getIdToken().getJwtToken();
  } catch {}
  const params: Partial<OpenAPIConfig> = {
    BASE: isLocal
      ? `http://localhost:3201`
      : `${process.env.REACT_APP_API_HOST}/media_store`,
    ...(token && { TOKEN: token }),
  };
  const mediaStoreApi = new MediaStoreApiClient(params);
  return mediaStoreApi;
};
