import Box from "@mui/joy/Box";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getMediaStoreApi } from "../../api";
import { MediaListResponse } from "../../generatedApi/mediaStoreApi";
import MediaCard from "../molecules/MediaCard";
import MediaSquareView from "../molecules/MediaSquareView";

type UserMediaListProps = {
  view: string;
  userId: string;
  startKey?: string | undefined;
};

const UserMediaList = (props: UserMediaListProps) => {
  const [images, setImages] = useState<MediaListResponse | null>(null);
  const listImages = useCallback(async () => {
    const api = await getMediaStoreApi();
    const res = await api.user.getMediaListByUser(props.userId);
    setImages(res);
  }, [props]);

  useEffect(() => {
    listImages();
  }, [listImages]);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {props.view === "tile" && (
        <Box display="flex" flexWrap="wrap" m={-0.25}>
          {images &&
            images.media_id_list.map((image_id, ikey) => (
              <Box
                key={ikey}
                flex="50%"
                maxWidth="50%"
                // component={Link}
                // to={"/post/" + image.media_id}
                overflow="auto"
              >
                <MediaSquareView mediaId={image_id} key={ikey} />
              </Box>
            ))}
        </Box>
      )}
      {props.view === "feed" && (
        <Box>
          {images &&
            images.media_id_list.map((media_id, mkey) => (
              <MediaCard mediaId={media_id} key={mkey} />
            ))}
        </Box>
      )}
    </>
  );
};

export default UserMediaList;
