import ReportIcon from "@mui/icons-material/Report";
import { Authenticator, translations } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Alert from "@mui/joy/Alert";
import Typography from "@mui/joy/Typography";
import { I18n } from "aws-amplify";
// import { getMediaStoreApi } from "../../api";
import Layout from "../molecules/Layout";
import { useSearchParams } from "react-router-dom";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const alertReason = searchParams.get("login") ?? null;
  return (
    <>
      <Layout>
        {alertReason && (
          <Alert
            color="danger"
            size="lg"
            variant="soft"
            startDecorator={<ReportIcon />}
            sx={{ mx: 1, mb: 2, width: { xs: "none", sm: "100%" } }}
          >
            <Typography fontWeight="lg">操作にはログインが必要です</Typography>
          </Alert>
        )}

        <Authenticator
          loginMechanisms={["email"]}
          signUpAttributes={["nickname"]}
          socialProviders={["google"]}
          formFields={{
            signIn: {
              username: {
                label: "メールアドレス",
                placeholder: "メールアドレスを入力",
              },
              password: {
                label: "パスワード",
                placeholder: "パスワードを入力",
              },
            },
            signUp: {
              nickname: {
                label: "ニックネーム",
                placeholder: "ニックネームを入力",
                order: 2,
              },
              email: {
                label: "メールアドレス",
                placeholder: "メールアドレスを入力",
                order: 1,
              },
              password: {
                label: "パスワード",
                placeholder: "パスワードを入力",
                order: 3,
              },
              confirm_password: {
                label: "パスワードの確認",
                placeholder: "パスワードを再入力してください",
                order: 4,
              },
            },
          }}
        ></Authenticator>
      </Layout>
    </>
  );
};

export default LoginPage;
