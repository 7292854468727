const config = {
  Auth: {
    region: process.env.REACT_APP_AWS_AUTH_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDPOOL_ID,
    userPoolId: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_CLIENT_ID,
    oauth: {
      userPoolId: process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
      domain: process.env.REACT_APP_COGNITO_DOMAIN?.replace("https://", ""),
      scope: ["openid", "email", "profile"],
      redirectSignIn: "https://" + process.env.REACT_APP_HOSTED_DOMAIN,
      redirectSignOut: "https://" + process.env.REACT_APP_HOSTED_DOMAIN,
      responseType: "code",
    },
  },
  Storage: {
    AWSS3: {
      region: process.env.REACT_APP_AWS_AUTH_REGION,
    },
  },
};

export default config;
