import { ChevronLeft } from "@mui/icons-material";
import { Button } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../molecules/Layout";
import MediaCard from "../molecules/MediaCard";
import RelatedImages from "../molecules/RelatedImages";

const ImageSinglePage = (props: {}) => {
  let { mediaId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Layout>
        <Button
          startDecorator={<ChevronLeft />}
          variant="soft"
          color="neutral"
          onClick={() => navigate("/")}
          sx={{ mb: 1 }}
        >
          トップページ
        </Button>
        {mediaId && <MediaCard mediaId={mediaId} singlePage={true} />}
        <RelatedImages />
      </Layout>
    </>
  );
};
export default ImageSinglePage;
