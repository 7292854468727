import { Tab, TabList, Tabs } from "@mui/joy";

type MediaViewChangerProps = {
  handleViewModeChange(viewMode: string): void;
  viewMode: string;
};
const MediaViewChanger = (props: MediaViewChangerProps) => {
  return (
    <Tabs
      aria-label="Soft tabs"
      value={props.viewMode}
      sx={{ borderRadius: "lg" }}
    >
      <TabList variant="soft">
        <Tab
          variant={props.viewMode === "feed" ? "solid" : "plain"}
          color={props.viewMode === "feed" ? "primary" : "neutral"}
          onChange={() => {
            props.handleViewModeChange("feed");
          }}
        >
          フィード
        </Tab>
        <Tab
          variant={props.viewMode === "tile" ? "solid" : "plain"}
          color={props.viewMode === "tile" ? "primary" : "neutral"}
          onChange={() => {
            props.handleViewModeChange("tile");
          }}
        >
          タイル
        </Tab>
      </TabList>
    </Tabs>
  );
};

export default MediaViewChanger;
