import { useGetMedia } from "../../hooks";
import { FeedCardView } from "./FeedCardView";

type MediaCardProps = {
  mediaId: string;
  singlePage?: boolean;
};
const MediaCard = (props: MediaCardProps) => {
  const { data: media, error: mediaError } = useGetMedia(props.mediaId);
  return (
    <>
      {mediaError !== undefined ? "画像の読み込みに失敗しました" : null}
      {media && (
        <FeedCardView
          username={media.user_id}
          imageSrc={media.image_src}
          mediaId={props.mediaId}
          timestamp={media.timestamp}
          description={
            media.auto_annotated_labels
              ? media.auto_annotated_labels.map((item) => "#" + item).join(" ")
              : "aaa"
          }
          singlePage={props.singlePage}
        />
      )}
    </>
  );
};

export default MediaCard;
