/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediaResponse } from '../models/MediaResponse';
import type { QueryMediaResponse } from '../models/QueryMediaResponse';
import type { UploadReference } from '../models/UploadReference';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MediaService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Query Media List
     * @param q
     * @param sortBy
     * @param sortAsc
     * @returns QueryMediaResponse Successful Response
     * @throws ApiError
     */
    public queryMediaList(
        q?: string,
        sortBy?: string,
        sortAsc: boolean = true,
    ): CancelablePromise<QueryMediaResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/media',
            query: {
                'q': q,
                'sort_by': sortBy,
                'sort_asc': sortAsc,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Prepare media upload
     * @param filename
     * @returns UploadReference Successful Response
     * @throws ApiError
     */
    public prepareMediaUpload(
        filename: string,
    ): CancelablePromise<UploadReference> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/media/prepare_upload',
            query: {
                'filename': filename,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get media by ID
     * @param mediaId
     * @returns MediaResponse Successful Response
     * @throws ApiError
     */
    public getMediaById(
        mediaId: string,
    ): CancelablePromise<MediaResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/media/{media_id}',
            path: {
                'media_id': mediaId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * @deprecated
     * Put media
     * @param docId
     * @param userId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public putMedia(
        docId: string,
        userId: string,
        requestBody?: Array<string>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/media/{doc_id}',
            path: {
                'doc_id': docId,
            },
            query: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
