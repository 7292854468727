import { Close } from "@mui/icons-material";
import { Box, Button, LinearProgress, Typography } from "@mui/joy";

import IconButton from "@mui/joy/IconButton";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Auth, Storage } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import { getMediaStoreApi } from "../../api";
import { useRequireLogin } from "../../hooks";
import { FeedCardView } from "../molecules/FeedCardView";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PhotoUploader = () => {
  let navigate = useNavigate();
  const { loginState, requireLogin } = useRequireLogin();
  useEffect(() => {
    requireLogin();
  }, [loginState, requireLogin]);

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [identityId, setIdentityId] = useState<string | undefined>(undefined);
  const [isUploading, setIsUploading] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const getCredentials = useCallback(async () => {
    const credentials = await Auth.currentUserCredentials();
    setIdentityId(credentials.identityId);
  }, []);

  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(file, 512, 512, "JPEG", 75, 0, (uri) => {
        resolve(uri);
      });
    });

  const previewImage = useCallback(async () => {
    if (selectedFile === undefined) return;
    const res = await resizeFile(selectedFile);
    setImageSrc(String(res));
    setOpen(true);
  }, [selectedFile]);

  useEffect(() => {
    previewImage();
    getCredentials();
  }, [selectedFile, previewImage, getCredentials]);

  const submitImage = useCallback(async () => {
    if (selectedFile === undefined) {
      return;
    }
    if (identityId === undefined) {
      return;
    }
    setIsUploading(true);
    setUploadProgress(0);
    const api = await getMediaStoreApi();
    try {
      const uploadRef = await api.media.prepareMediaUpload(selectedFile.name);
      const res = await Storage.put(uploadRef.key, selectedFile, {
        bucket: uploadRef.bucket,
        // TODO: extract content type
        contentType: "image/png",
        level: "public",
        progressCallback: (progress) => {
          setUploadProgress(progress.loaded / progress.total);
        },
      });
    } catch {
    } finally {
      setIsUploading(false);
      setHasUploaded(true);
    }

    // navigate('/post/' + res.doc_id, {replace: true})
  }, [selectedFile, identityId, navigate]);

  const onSelectFile = (e: React.SyntheticEvent) => {
    // @ts-ignore
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // @ts-ignore
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Box>
      <Box textAlign="center">
        <Button
          variant="solid"
          color="primary"
          size="md"
          component="label"
          sx={{ minWidth: 300 }}
          onClick={() => {
            if (loginState) {
            } else {
              navigate("/login?login=false");
            }
          }}
        >
          写真を投稿する...
          {loginState ? (
            <input type="file" hidden onChange={onSelectFile} />
          ) : null}
        </Button>
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <Box
          borderBottom="1px solid"
          borderColor="neutral.100"
          height={(theme) => theme.spacing(6)}
          px={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight="lg">写真を投稿</Typography>
          <IconButton
            size="sm"
            color="neutral"
            variant="soft"
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Box maxWidth="470px" mx="auto" mt={1} mb={2}>
          {imageSrc && <FeedCardView imageSrc={imageSrc} sx={{ mx: "auto" }} />}
          <Box mt={2} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              size="md"
              component="label"
              sx={{ mb: 1, minWidth: 300 }}
              disabled={isUploading || hasUploaded}
            >
              写真を選びなおす
              <input type="file" hidden onChange={onSelectFile} />
            </Button>
            <br />
            <Button
              sx={{ minWidth: 300 }}
              variant="solid"
              color="primary"
              size="md"
              component="label"
              // disabled={isUploading || hasUploaded}
              disabled={isUploading}
              onClick={() => submitImage()}
            >
              {!(isUploading || hasUploaded) && "投稿"}
              {isUploading && "投稿中..."}
              {hasUploaded && "投稿しました🎉"}
            </Button>
            {isUploading && (
              <LinearProgress
                determinate
                value={uploadProgress * 100}
                sx={{ mt: 1 }}
              />
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default PhotoUploader;
