import { useAuthenticator } from "@aws-amplify/ui-react";
import { CssVarsProvider } from "@mui/joy/styles";
import Amplify from "aws-amplify";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import config from "./aws-exports";
import Home from "./components/pages/Home";
import ImageSinglePage from "./components/pages/ImageSinglePage";
import LoginPage from "./components/pages/LoginPage";
import UserPage from "./components/pages/UserPage";
import customTheme from "./constant/theme";
Amplify.configure(config);

type Props = {
  element: React.ReactNode;
};

const LoginRoute = (props: Props) => {
  const { element } = props;
  const { route } = useAuthenticator((context) => [context.route]);
  if (route === "authenticated") {
    return (
      <>
        <Navigate to="/" replace={false} />
      </>
    );
  } else {
    return <>{element}</>;
  }
};

const App: React.FC = () => {
  return (
    <CssVarsProvider theme={customTheme}>
      <BrowserRouter>
        <Routes>
          {/*
            <Route path="/" element={ <HomePage /> } />
          <Route path="/camera" element={ <CameraPage /> } />
          <Route path="/preview" element={ <PreviewPage /> } />
          |*/}
          <Route
            path="/login"
            element={<LoginRoute element={<LoginPage />} />}
          />
          <Route path="/" element={<Home />} />
          <Route path="/post/:mediaId" element={<ImageSinglePage />} />
          <Route path="/user/:userId" element={<UserPage />} />
        </Routes>
      </BrowserRouter>
    </CssVarsProvider>
  );
};

export default App;
