import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Layout from "../molecules/Layout";
import MediaViewChanger from "../molecules/MediaViewChanger";
import UserMediaList from "../organisms/UserMediaList";
import { useGetPublicUser } from "../../hooks";

const UserPage = (props: {}) => {
  let { userId } = useParams();
  const navigate = useNavigate();
  const { data: user } = useGetPublicUser(userId);
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewMode, setViewMode] = useState(
    searchParams.get("viewMode") ?? "feed"
  );

  useEffect(() => {
    setViewMode(searchParams.get("viewMode") ?? "feed");
  }, [searchParams]);

  const handleViewModeChange = (newValue: any) => {
    setViewMode(newValue);
    setSearchParams({ viewMode: newValue });
  };

  return (
    <>
      <Layout>
        <Button
          startDecorator={<ChevronLeft />}
          variant="soft"
          color="neutral"
          onClick={() => navigate("/")}
          sx={{ mb: 1 }}
        >
          トップページ
        </Button>
        <Box mb={3} mt={1}>
          <Typography fontSize="xl">{user?.nickname} さんの投稿</Typography>
        </Box>
        <Box mb={1}>
          <MediaViewChanger
            handleViewModeChange={handleViewModeChange}
            viewMode={viewMode}
          />
        </Box>
        {userId && <UserMediaList view={viewMode} userId={userId} />}
      </Layout>
    </>
  );
};
export default UserPage;
