import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";
import { getMediaStoreApi } from "./api";
import useSWR from "swr";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const useGetMedia = (mediaId: string | undefined) => {
  const fetchMedia = async () => {
    if (mediaId === undefined) return undefined;
    const api = await getMediaStoreApi();
    const data = await api.media.getMediaById(mediaId);
    return data;
  };

  return useSWR(`/media/${mediaId}`, fetchMedia);
};

export const useGetUserId = () => {
  const [userId, setUserId] = useState<string | "userId">("userId");
  const getUserId = useCallback(async () => {
    const token = (await Auth.currentSession()).getIdToken();
    setUserId(token.payload.sub);
  }, []);

  return { userId, getUserId };
};

export const useGetPublicUser = (userId: string | undefined) => {
  const fetchPublicUser = async () => {
    if (userId === undefined) return undefined;
    const api = await getMediaStoreApi();
    const data = api.user.getPublicUser(userId);
    return data;
  };

  return useSWR(`/user/${userId}`, fetchPublicUser);
};

export const useRequireLogin = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const [loginState, setLoginState] = useState(false);
  const requireLogin = () => {
    if (route === "authenticated") {
      setLoginState(true);
    } else {
      setLoginState(false);
    }
  };
  return { loginState, requireLogin };
};
