import "@aws-amplify/ui-react/styles.css";
import { Box, Divider, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../molecules/Layout";
import MediaViewChanger from "../molecules/MediaViewChanger";
import MediaList from "../organisms/MediaList";
import PhotoUploader from "../organisms/PhotoUploader";

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewMode, setViewMode] = useState(
    searchParams.get("viewMode") ?? "feed"
  );

  useEffect(() => {
    setViewMode(searchParams.get("viewMode") ?? "feed");
  }, [searchParams]);

  const handleViewModeChange = (newValue: any) => {
    setViewMode(newValue);
    setSearchParams({ viewMode: newValue });
  };

  return (
    <>
      <Layout>
        <Box mb={2}>
          <PhotoUploader />
        </Box>
        <Box mb={1}>
          <MediaViewChanger
            handleViewModeChange={handleViewModeChange}
            viewMode={viewMode}
          />
        </Box>
        <MediaList view={viewMode} sortBy="timestamp" sortAsc={false} />
        {/* {im && <img src={im} />} */}
        <Box py={1}>
          <Divider sx={{ my: 1 }} />
          <Typography level="body3">
            ver.
            {process.env.REACT_APP_DEPLOY_COMMIT_ID} (
            {process.env.REACT_APP_DEPLOY_EXECUTED_BY})
          </Typography>
        </Box>
      </Layout>
    </>
  );
};

export default Home;
