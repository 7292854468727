import Box from "@mui/joy/Box";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getMediaStoreApi } from "../../api";
import { QueryMediaResponse } from "../../generatedApi/mediaStoreApi";
import { FeedCardView } from "../molecules/FeedCardView";
import MediaCard from "../molecules/MediaCard";
import MediaSquare from "../molecules/MediaSquare";
import MediaSquareView from "../molecules/MediaSquareView";

type MediaListProps = {
  view: string;
  query?: string;
  sortBy?: "timestamp" | undefined;
  sortAsc?: boolean | undefined;
};

const MediaList = (props: MediaListProps) => {
  const [images, setImages] = useState<QueryMediaResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const listImages = useCallback(async () => {
    const api = await getMediaStoreApi();
    const res = await api.media.queryMediaList(
      props.query,
      props.sortBy,
      props.sortAsc ?? true
    );
    setImages(res);
    setLoading(false);
  }, [props]);

  useEffect(() => {
    listImages();
  }, [listImages]);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {props.view === "tile" && (
        <Box display="flex" flexWrap="wrap" m={-0.25}>
          {loading
            ? [...Array(5)].map((image_id, ikey) => (
                <Box key={ikey} flex="50%" maxWidth="50%" overflow="auto">
                  <MediaSquare image_src="loading" mediaId="loading" />
                </Box>
              ))
            : images &&
              images.media_id_list.map((image_id, ikey) => (
                <Box key={ikey} flex="50%" maxWidth="50%" overflow="auto">
                  <MediaSquareView mediaId={image_id} key={ikey} />
                </Box>
              ))}
        </Box>
      )}
      {props.view === "feed" && (
        <Box>
          {loading ? (
            <FeedCardView loading={loading} />
          ) : (
            images &&
            images.media_id_list.map((media_id, mkey) => (
              <MediaCard mediaId={media_id} key={mkey} />
            ))
          )}
        </Box>
      )}
    </>
  );
};

export default MediaList;
