import { Box, Tab, TabList, Tabs } from "@mui/joy";
import MediaList from "../organisms/MediaList";

type RelatedImagesProps = {
  words?: string[];
};
const RelatedImages = (props: RelatedImagesProps) => {
  return (
    <Box>
      <Box mb={1}>
        <Tabs sx={{ width: "auto" }}>
          <TabList variant="outlined" size="sm">
            <Tab variant="soft">関連画像</Tab>
            <Tab variant="plain">関連年代</Tab>
            <Tab variant="plain">関連スポット</Tab>
          </TabList>
        </Tabs>
      </Box>
      {props.words && <MediaList view="tile" query={props.words.join(" ")} />}
    </Box>
  );
};

export default RelatedImages;
