/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediaListResponse } from '../models/MediaListResponse';
import type { PrivateUser } from '../models/PrivateUser';
import type { PublicUser } from '../models/PublicUser';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Public User
     * @param userId
     * @returns PublicUser Successful Response
     * @throws ApiError
     */
    public getPublicUser(
        userId: string,
    ): CancelablePromise<PublicUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Private User
     * @param userId
     * @returns PrivateUser Successful Response
     * @throws ApiError
     */
    public getPrivateUser(
        userId: string,
    ): CancelablePromise<PrivateUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{user_id}/private',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User Nickname
     * @param userId
     * @param newNickname
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateUserNickname(
        userId: string,
        newNickname: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/{user_id}/nickname',
            path: {
                'user_id': userId,
            },
            query: {
                'new_nickname': newNickname,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get media list by user
     * @param userId
     * @param startKey
     * @returns MediaListResponse Successful Response
     * @throws ApiError
     */
    public getMediaListByUser(
        userId: string,
        startKey?: string,
    ): CancelablePromise<MediaListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{user_id}/media',
            path: {
                'user_id': userId,
            },
            query: {
                'start_key': startKey,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
